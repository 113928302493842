@import '~react-toastify/scss/variables';
@import 'variables';

$rt-toast-background: #fff;
$rt-color-default: #fff;
$rt-color-dark: $secondary;
$rt-color-info: $primary;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;
$rt-text-color-default: $darkgray-3;
$rt-text-color-dark: #fff;
$rt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
);

@import '~react-toastify/scss/toastContainer';
@import '~react-toastify/scss/toast';
@import '~react-toastify/scss/closeButton';
@import '~react-toastify/scss/progressBar';

// entrance and exit animations
@import '~react-toastify/scss/animations/bounce.scss';
@import '~react-toastify/scss/animations/zoom.scss';
@import '~react-toastify/scss/animations/flip.scss';
@import '~react-toastify/scss/animations/slide.scss';

.Toastify__toast-container {
  width: 376px !important;

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: $success;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: $danger-2;
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background: $warning-7;
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background: $th-blue-6;
  }

  .Toastify__toast {
    padding: 0.2rem 0.6rem 0.2rem 0.3rem;
    border-radius: 0.25rem;
    word-break: break-word; //issue:cutting text, if text is large with no space

    .Toastify__close-button {
      margin: auto;
    }
  }
}
