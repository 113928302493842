@import '/src/assets/app-styles/scss/variables';

.carosel-container {
  .indicators {
    color: var(--lightgray-1);
  }
  .indicators:hover {
    color: var(--primary-2);
  }
  .active-indicator {
    color: var(--primary-1);
  }

  .MuiIconButton-label {
  }
}
