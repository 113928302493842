.logo-apex {
  img {
    margin-top: 10px;
    max-width: 100%;
  }
}

.logo-onroll {
  img {
    margin-top: 10px;
    width: 100px;
  }
}
