@import 'variables';

.heading-lg {
    font-size: 56px;
    line-height: 5rem;

    @media (max-width:1199px) {
        font-size: 3rem;
        line-height: 4rem;
    }

    @media (max-width:991px) {
        font-size: 2.25rem;
        line-height: 3rem;
    }

    @media (max-width:767px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.auth-bg-image {

    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: calc(100% + 30px);
    height: 100%;

    img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 62%;

        // width: 840px;
        @media (max-width:1440px) {
            // width: 640px;
        }

        @media (max-width:1280px) {
            // width: 580px;
        }
    }
}

.apex-logo {
    &.logo-small {
        img {
            width: 200px;
        }
    }
}

.back-to-login {
    position: absolute;
    left: 60px;
    bottom: 60px;
    @media (max-width: 360px) {
        bottom: 30px;
    }
}