.sidebar-wrapper {
  // .first-arrow {
  //   grid-column: 1;
  //   grid-row: 1;
  //   align-items: center;
  //   justify-content: center;
  //   display: flex;
  //   margin: 0 10px;
  // }

  // .second-arrow {
  //   grid-column: 3;
  //   grid-row: 1;
  //   align-items: center;
  //   justify-content: center;
  //   display: flex;
  //   margin: 0 10px;
  // }
  .first-arrow {
    position: absolute;
    top: 15px;
    left: 0.9375rem;
  }

  .second-arrow {
    position: absolute;
    top: 15px;
    left: calc(100% - 8.875rem);
    margin-left: 10px;
  }

  .close-all-tabs {
    grid-column: 4;
    grid-row: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0px;
    span {
      padding: 5px;
      border-left: 2px solid lightgrey;
    }
    .close-btn-icon {
      width: 20px;
      height: 20px;
    }
  }
  .tab-content {
    grid-column: 1 / span 4;
    grid-row: 2;
  }
  nav {
    flex-wrap: unset;
  }
}
