.input-group-container{
    .input-start{
        font-size: 13px;
        padding: 0px 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 40px;
        color:#6d777f
    }
    .input-field{
        background-color: #f4f6f8 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .input-end{
        font-size: 13px;
        padding: 0px 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 40px;
        color:#6d777f;
        cursor: pointer;
    }
}