// blue theme
// old blue color commented for safe site
// $th-blue: #2d2d95;
// $th-blue-1: #1c1c78;
// $th-blue-2: #3b3bba;
// $th-blue-3: #c1c1fa;
// $th-blue-4: #ececfd;
// $th-dark-blue: #212169;
// $th-light-blue: #a5a5ea;

// Turquoise green theme
$th-turq-green: #08a0aa;
$th-turq-green-1: #f9f9f9;
$th-turq-green-2: #08a0aa;
$th-turq-green-3: #e8fdff;
$th-turq-green-4: #e4e3e2;
$th-turq-green-5: #59b7be;
$th-dark-turq-green: #045359;
$th-light-turq-green: #88f5fd;

// dark grey theme
$th-dark-grey: #3c4043;
$th-dark-grey-1: #f9f9f9;
$th-dark-grey-2: #3c4043;
$th-dark-grey-3: #d2d2d2;
$th-dark-grey-4: #e4e3e2;
$th-dark-grey-5: #6d7479;
$th-dark-dark-grey: #2f3233;
$th-light-dark-grey: #72777b;

//  Eco Green theme
$th-eco-green: #05a128;
$th-eco-green-1: #f9f9f9;
$th-eco-green-2: #13b636;
$th-eco-green-3: #c2ffcf;
$th-eco-green-4: #e4e3e2;
$th-eco-green-5: #69ba7a;
$th-dark-eco-green: #035815;
$th-light-eco-green: #8dd39c;

// Orange theme
$th-orange: #e17203;
$th-orange-1: #f9f9f9;
$th-orange-2: #f07b06;
$th-orange-3: #fdf1e5;
$th-orange-4: #e4e3e2;
$th-orange-5: #dd964f;
$th-dark-orange: #e27908;
$th-light-orange: #f0b77e;

// new blue color theme pallete
$th-blue: #005aa7;
$th-blue-1: #f9f9f9;
$th-blue-2: #1a66a7;
$th-blue-3: #f2fafb;
$th-blue-4: #edeff2;
$th-blue-5: #419cf6;
$th-blue-6: #175c96;
$th-dark-blue: #134775;
$th-light-blue: #dcf7ff;

// $blue : #005aa7;
// $blue-1: #1c1c78;
// $blue-2: #005aa7;
// $blue-3: #edeff2;
// $blue-4: #edeff2;
// $light-blue: #a5a5ea;

// gray theme
$th-gray: #454f5b;
$th-gray-1: #f9f9f9;
$th-gray-2: #2d3c52;
$th-gray-3: #edeff2;
$th-gray-4: #e4e3e2;
$th-gray-5: #606e80;
$th-dark-gray: #373e47;
$th-light-gray: #c4cdd5;

//blazeo theme
$th-blazeo: #24183d;
$th-blazeo-1: #e3ddf1;
$th-blazeo-2: #2d3c52;
$th-blazeo-3: #ededf1;
$th-blazeo-4: #e4e3e2;
$th-blazeo-5: #3c2884;
$th-dark-blazeo: #24183d;
$th-light-blazeo: #c4cdd5;

// $th-gray: #454f5b;
// $th-gray-1: #212b36;
// $th-gray-2: #27333f;
// $th-gray-3: #ffffff;
// $th-gray-4: #dfe3e8;
// $th-dark-gray: #373e47;
// $th-light-gray: #f9fafb;

$primary: $th-blue;
$primary-1: $th-blue-1;
$primary-2: $th-blue-2;
$primary-3: $th-blue-3;
$primary-4: $th-blue-4;
$primary-5: $th-blue-5;
$primary-6: $th-blue-3;
$primary-light: $th-light-blue;
$primary-dark: $th-dark-blue;
$info: $primary-light;
$disabled-gray: #989898;
$purple: #7b0ca1;

$dark: #212b36;
$black: #454f5b;
$darkgray: #212b36;
$secondary: $darkgray;
$secondary-2: $primary-5;
$darkgray-1: #191919;
$darkgray-2: #637381;
$darkgray-3: #919eab;
$darkgray-4: #2d3c52;
$darkgray-5: #5a6672;
$darkgray-6: #e4e3e2;
$darkgray-7: #cbdbe8;
$darkgray-8: #dddddd;
$darkgray-9: #a8acb0;

$lightgray: #c4cdd5;
$lightgray-1: #dfe3e8;
$lightgray-2: #f4f6f8;
$lightgray-3: #f9fafb;
$lightgray-4: #abb3bc;
$lightgray-5: #f2fafb;
$lightgray-6: #f8f8f8;

$success: #108043;
$success-1: #173630;
$success-2: #50b83c;
$success-3: #bbe5b3;
$success-4: #e3f1df;
$success-5: #8ab782;
$success-6: #b9e7b9;
$success-7: #b9e7b9;

$warning: #f49342;
$warning-1: #4a1504;
$warning-2: #c05717;
$warning-3: #ffc58b;
$warning-4: #fcebdb;
$warning-5: #ffebeb;
$warning-6: #704500;
$warning-7: #e99e06;

$danger: #e53427;
$danger-1: #330101;
$danger-1: #bf0711;
$danger-2: #de3618;
$danger-3: #fe6464;
$danger-4: #fbeae5;

$white: #fff;

$unit: 8px;

$colors: (
  primary: $primary,
  blue: $primary,
  primary-1: $primary-1,
  primary-2: $primary-2,
  primary-3: $primary-3,
  primary-4: $primary-4,
  primary-5: $primary-5,
  primary-6: $primary-6,
  primary-light: $primary-light,
  primary-dark: $primary-dark,
  darkgray: $darkgray,
  secondary: $secondary,
  secondary-2: $secondary-2,
  darkgray-1: $darkgray-1,
  darkgray-2: $darkgray-2,
  darkgray-3: $darkgray-3,
  darkgray-7: $darkgray-7,
  darkgray-9: $darkgray-9,
  lightgray: $lightgray,
  lightgray-1: $lightgray-1,
  lightgray-2: $lightgray-2,
  lightgray-3: $lightgray-3,
  lightgray-4: $lightgray-4,
  lightgray-5: $lightgray-5,
  disabled-gray: $disabled-gray,
  success: $success,
  success-1: $success-1,
  success-2: $success-2,
  success-3: $success-3,
  success-4: $success-4,
  success-5: $success-5,
  warning: $warning,
  warning-1: $warning-1,
  warning-2: $warning-2,
  warning-3: $warning-3,
  warning-4: $warning-4,
  warning-7: $warning-7,
  danger: $danger,
  danger-1: $danger-1,
  danger-2: $danger-2,
  danger-3: $danger-3,
  danger-4: $danger-4,
  white: $white,
  info-1: $primary-light,
  // blue theme
  th-blue: $th-blue,
  th-blue-1: $th-blue-1,
  th-blue-2: $th-blue-2,
  th-blue-3: $th-blue-3,
  th-blue-4: $th-blue-4,
  th-blue-5: $th-blue-5,
  th-blue-6: $th-blue-6,
  th-light-blue: $th-light-blue,
  th-dark-blue: $th-dark-blue,
  // gray theme
  th-gray: $th-gray,
  th-gray-1: $th-gray-1,
  th-gray-2: $th-gray-2,
  th-gray-3: $th-gray-3,
  th-gray-4: $th-gray-4,
  th-gray-5: $th-gray-5,
  th-light-gray: $th-light-gray,
  th-dark-gray: $th-dark-gray,
  //Blazeo theme
  th-blazeo: $th-blazeo,
  th-blazeo-1: $th-blazeo-1,
  th-blazeo-2: $th-blazeo-2,
  th-blazeo-3: $th-blazeo-3,
  th-blazeo-4: $th-blazeo-4,
  th-blazeo-5: $th-blazeo-5,
  th-light-blazeo: $th-light-blazeo,
  th-dark-blazeo: $th-dark-blazeo,
  // Turquoise Green theme
  th-turq-green: $th-turq-green,
  th-turq-green-1: $th-turq-green-1,
  th-turq-green-2: $th-turq-green-2,
  th-turq-green-3: $th-turq-green-3,
  th-turq-green-4: $th-turq-green-4,
  th-turq-green-5: $th-turq-green-5,
  th-dark-turq-green: $th-dark-turq-green,
  th-light-turq-green: $th-light-turq-green,
  // Dark Grey theme
  th-dark-grey: $th-dark-grey,
  th-dark-grey-1: $th-dark-grey-1,
  th-dark-grey-2: $th-dark-grey-2,
  th-dark-grey-3: $th-dark-grey-3,
  th-dark-grey-4: $th-dark-grey-4,
  th-dark-grey-5: $th-dark-grey-5,
  th-dark-dark-grey: $th-dark-dark-grey,
  th-light-dark-grey: $th-light-dark-grey,
  //  Eco Green theme
  th-eco-green: $th-eco-green,
  th-eco-green-1: $th-eco-green-1,
  th-eco-green-2: $th-eco-green-2,
  th-eco-green-3: $th-eco-green-3,
  th-eco-green-4: $th-eco-green-4,
  th-eco-green-5: $th-eco-green-5,
  th-dark-eco-green: $th-dark-eco-green,
  th-light-eco-green: $th-light-eco-green,
  // Orange theme
  th-orange: $th-orange,
  th-orange-1: $th-orange-1,
  th-orange-2: $th-orange-2,
  th-orange-3: $th-orange-3,
  th-orange-4: $th-orange-4,
  th-orange-5: $th-orange-5,
  th-dark-orange: $th-dark-orange,
  th-light-orange: $th-light-orange
);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'blue': map-get($colors, blue),
    'darkgray': map-get($colors, darkgray)
  ),
  $theme-colors
);

@each $colorKey, $name in $colors {
  // $colorName: var(--$colorKey);
  $colorName: var(--#{$colorKey});
  .text-#{$colorKey} {
    color: $colorName !important;
  }

  .bg-#{$colorKey} {
    background-color: $colorName !important;
  }
  .btn-#{$colorKey} {
    background-color: $colorName !important;
  }

  .btn-outline-#{$colorKey} {
    border-color: $colorName !important;
    color: $colorName !important;
    box-shadow: 0 4px 8px 0 rgba($colorName, 0);
    background-color: rgba(255, 255, 255, 0) !important;

    &:hover,
    &:focus {
      background-color: $colorName !important;
      color: #fff !important;
    }
  }

  .btn-#{$colorKey} {
    color: #fff !important;
    box-shadow: 0 4px 8px 0 rgba($colorName, 0.24);

    &:hover,
    &:focus {
      background-color: darken($name, 4%) !important;
      box-shadow: 0 6px 12px 0 rgba($name, 0.24);
      // box-shadow: 0 0 0 0.2rem rgba($colorName, 0.5) !important;
    }
  }
}

.btn-lightgray-3 {
  border: 1px solid $lightgray;
  color: $darkgray-2 !important;
}

@mixin badgeVariant($bg, $text) {
  background-color: $bg;
  color: $text;
}

.badge {
  //   border: 1px solid;
  font-size: 11px;
  font-weight: 600;
  // padding: 2px 4px 2px 2px;
  background-color: var(--primary);

  //   &-text {
  //     margin-left: 2px;
  //   }

  svg,
  img {
    width: 12px;
    height: 12px;
  }

  &-primary {
    // conflict styles on other badges e.g: in companies heading badge
    // @include badgeVariant($primary-4, $primary-2, $primary);
  }

  &-secondary {
    @include badgeVariant($darkgray-2, $white);
  }

  &-success {
    @include badgeVariant($success, $white);
  }

  &-danger {
    @include badgeVariant($danger, $white);
  }

  &-warning {
    @include badgeVariant($warning, $white);
  }

  //   &-info {
  //   }

  //   &-light {
  //   }

  //   &-dark {
  //   }

  //   &-transparent {
  //     @include badgeVariant(transparent, $lightgray, $dark);
  //   }
}

$fontSizer: (
  10,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  35,
  36,
  38,
  40,
  42,
  44,
  45,
  50,
  60,
  70,
  80,
  200,
  240,
  250
);

$fontWeight: (100, 300, 400, 500, 600, 700, 900);

@each $fontsize in $fontSizer {
  .fz-#{$fontsize} {
    font-size: #{pxToRem($fontsize)} !important;
  }

  .rounded-#{$fontsize} {
    border-radius: #{$fontsize}px !important;
  }
}

@each $fontweight in $fontWeight {
  .fw-#{$fontweight} {
    font-weight: #{$fontweight} !important;
  }
}

$liftSizer: (
  0,
  1,
  2,
  3,
  4,
  5,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  200,
  300,
  400,
  500,
  1000,
  2000,
  3000,
  4000,
  5000
);

@each $lift in $liftSizer {
  .lift-#{$lift} {
    z-index: #{$lift} !important;
  }
}

$spacers: () !default;
$spacers: map-merge(
  (
    1r: 0.25rem,
    2r: 0.5rem,
    3r: 0.75rem,
    4r: 1rem,
    5r: 1.25rem,
    6r: 1.5rem,
    7r: 1.75rem,
    8r: 2rem,
    9r: 2.25rem,
    10r: 2.5rem,
    11r: 2.75rem,
    12r: 3rem,
    4: 4px,
    8: 8px,
    12: 12px,
    16: 16px,
    20: 20px,
    24: 24px,
    32: 32px,
    36: 36px,
    40: 40px,
    48: 48px,
    56: 56px,
    64: 64px,
    72: 72px,
    80: 80px,
    88: 88px,
    96: 96px,
    104: 104px,
    112: 112px,
    120: 120px,
    136: 136px,
    152: 152px
  ),
  $spacers
);

.vertical-middle {
  vertical-align: middle;
}

.text-upper {
  text-transform: uppercase;
}

p:last-child {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

@mixin transition($delay) {
  transition: all ease $delay;
}
