@import '/src/assets/app-styles/scss/variables';
.app-banner{
    width: 100%;
    background: var(--primary-dark);
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    position: fixed;
    z-index: 1500;
    top: 0;
    .app-icon{
        width: 40px;
        border-radius: 4px;
    }
    .btn{
        border-radius: 50px;
        padding: 0.5rem;
        font-size: small;
    }
    p{    
        margin-bottom: auto;
        color: var(--primary-3);
    }
    .app-name{
        font-weight: bold;
    }
    .help-text{
        font-size: small;
    }
}