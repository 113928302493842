.grid-id-field {
  .MuiOutlinedInput-root.Mui-focused:not(.Mui-error)
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--primary) !important;
  }

  height: 40px;
  padding: 3px 5px !important;
  background: transparent;
  > div {
    background: white;
    padding-right: 2px;
    &:focus {
      border: 1px solid var(--primary) !important;
    }
  }
  input {
    padding: 8px;
    background: white;
    font-size: 14px;
    // &:focus {
    //   border: 1px solid var(--primary) !important;
    // }
  }
}
