@import '/src/assets/app-styles/scss/variables';

.sidebar-bottom-menu-second-last {
  .heading {
    font-size: 13px;
    font-weight: 600;
    color: $primary-1;
    cursor: pointer;
  }
  .option {
    font-size: 13px;
    color: $primary-1;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
