@import '/src/assets/app-styles/scss/variables';
.time-picker-styles {
  color: $darkgray-2 !important;
}

.form-group .is-invalid {
  border-color: #bf0711 !important;
  background-image: unset !important;
}
.MuiOutlinedInput-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-5);
  }

  fieldset {
    border-color: var(--primary-5) !important;
  }
}
.MuiFormLabel-root.Mui-focused {
  color: var(--primary-5) !important;
}
.date-picker-wrapper {
  position: absolute;
  top: 42px;
  z-index: 2;

  .calendar-border {
    border: 1px solid $lightgray-1;
    border-bottom: none;
  }
  .rdrMonth {
    padding: 0 0.833em;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--primary) !important;
  }
  .rdrDateDisplayItemActive {
    color: var(--primary) !important;
  }
  .rdrSelected {
    color: var(--primary-5) !important;
  }
  .action-buttons {
    background: white;
    border: 1px solid $lightgray-1;
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-top: 0px;
  }
}
