@import '/src/assets/app-styles/scss/variables';
@import '/src/assets/app-styles/scss/functions';

.cropContainer {
  position: relative;
  width: 100%;
  height: 438px;
  background: #000,
}
.controls {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.sliderLabel{
  width: auto;
}
.sliderC {
  padding: 22px 0px;
  margin-left: 26px;
  margin-right: 26px;
}
.sliderContainer{
    display: flex;
    flex: 1;
    align-items: center;
}
.cropped-modal-body{
  padding: 0px;
}
.cropped-modal-header {
  color: $darkgray-1;
  font-weight: bold;
  border: none;
  font-family: Lato;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-header{
  border-top-left-radius: 4px!important;
  border-top-right-radius: 4px!important;
}
.cropped-modal-header-container {
  background: $lightgray-2;
}

.cropped-modal-footer {
  background: $lightgray-2;
  border: none;
  justify-content: center;
}