.main-content-container {
  .hide-comp {
    -webkit-animation: fadeinout .3s linear forwards;
    animation: fadeinout .3s linear forwards;
  }
  
  @-webkit-keyframes fadeinout {
    0% { opacity: 0.6; }
    50% { opacity: 0.2; }
    100% { opacity: 0; }
  }
  
  @keyframes fadeinout {
    0% { opacity: 0.6; }
    50% { opacity: 0.2; }
    100% { opacity: 0; }
  }
  
  .show-comp {
    -webkit-animation: display .5s linear forwards;
    animation: display .5s linear forwards;
  }
  
  
  @-webkit-keyframes display {
    0% { opacity: 0.2; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
  }
  
  @keyframes display {
    0% { opacity: 0.2; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
  }
}
