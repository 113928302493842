.tour-greeting-modal{
  .greeting-img-hidden{
    visibility: 'hidden';
  }
  .greeting-img-visible{
    visibility: 'hidden';
  }
  .load-img-spinner{
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center               ;
      width: 100%;
      & .spinner-border {
        width: 4rem;
        height: 4rem;
      }
  }

}
.tour-modal-footer {
  .button-padding {
    padding: 0.5rem 1rem;
  }
}
