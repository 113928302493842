.edgebutton {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px dashed var(--primary-5);
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1;
  color: var(--primary);
  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
}
