.swal-container{
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .swal-icon
    {
        position: relative;
        padding: 20px 0;
        svg{
        z-index: 2;
        font-size: 4rem;
        position: relative;
        color: var(--primary-5);
        }
        &::before{
            content: '';
            position: absolute;
            width: 120px;
            height: 120px;
            transform: translate(-50%, -50%);
            background-color: var(--primary-3);
            top: 50%;
            left: 50%;
            z-index: 1;
            border-radius: 50%; 
        }
    }
}